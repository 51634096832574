<template>
  <div class="content">
    <el-card shadow="never" class="new-card" style="height: 100%">
      <div slot="header" class="card-header">
        <div class="card-header-left">
          <div class="top-title">大区关联</div>
        </div>
        <div class="card-header-left">
          <div class="top-title">
            <el-upload
              style="display: inline"
              action
              :on-change="(file, fileList) => handleChange(file, fileList)"
              :auto-upload="false"
              :show-file-list="false"
              accept=".xls,.xlsx"
            >
              <el-button size="small" style="margin: 0 10px" type="primary"
                >导入</el-button
              >
            </el-upload>
            <el-button type="primary" size="small" @click="onExport()"
              >导出</el-button
            >
            <el-button type="primary" size="small" @click="onAdd()"
              >添加</el-button
            >
            <el-button type="danger" size="small" @click="empty"
              >清空</el-button
            >
          </div>
        </div>
      </div>
      <div :style="{ overflowY: 'auto', height: tableHeight }">
        <el-table size="small" :data="tableData" border height="calc(100%)">
          <el-table-column label="部门编码" prop="deptCode" align="center" />
          <el-table-column label="部门名称" prop="deptName" align="center" />
          <el-table-column
            label="关联省份"
            prop="relationDesc"
            align="center"
          />
          <el-table-column label="操作人" align="center" prop="updateBy">
          </el-table-column>
          <el-table-column label="操作" align="center" width="170">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="small"
                @click="handleEdit(scope.row)"
                >修改</el-button
              >
              <el-button
                type="primary"
                size="small"
                @click="handleDelete(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
    <!-- 创建编辑弹窗 -->
    <el-dialog
      :title="modelTitle"
      :visible.sync="showModel"
      width="750px"
      :append-to-body="true"
    >
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="选择大区">
          <el-select
            placeholder="请选择"
            size="small"
            filterable
            :disabled="modelTitle === '修改大区'"
            v-model="formInline.deptCode"
            @change="onChangeDept"
          >
            <el-option
              v-for="item in deptList"
              :key="item.deptCode"
              :label="item.deptName"
              :value="item.deptCode"
            />
          </el-select>
        </el-form-item>
        <div v-for="(item, index) in formInline.provinceList" :key="index">
          <el-form-item label="选择省份">
            <el-select
              placeholder="请选择"
              size="small"
              filterable
              v-model="item.provinceCode"
              @change="(val) => onchangeProvince(val, index)"
              @focus="onFocusProvince"
            >
              <el-option
                v-for="pItem in pList"
                :key="pItem.provinceCode"
                :label="pItem.provinceName"
                :value="pItem.provinceCode"
                :disabled="pItem.disabled"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="选择城市">
            <el-select
              placeholder="请选择"
              size="small"
              filterable
              multiple
              v-model="item.cityList"
            >
              <el-option
                v-for="citem in item.cList"
                :key="citem.cityCode"
                :label="citem.cityName"
                :value="citem.cityCode"
                :disabled="!citem.canBind"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              v-show="index === 0"
              size="small"
              type="primary"
              @click="onMore"
              >添加更多</el-button
            >
            <el-button
              v-show="index !== 0"
              type="danger"
              size="small"
              icon="el-icon-delete"
              circle
              @click="onRemove(index)"
            ></el-button>
          </el-form-item>
        </div>
      </el-form>

      <div slot="footer">
        <el-button size="small" @click="onCancelForm">取 消</el-button>
        <el-button size="small" type="primary" @click="submitForm"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import store from "@/store";
export default {
  data() {
    return {
      tableData: [], // 表格数据
      showModel: false, // 是否显示创建编辑弹窗
      tableHeight: "850px",
      modelTitle: "添加地区",
      deptList: [],
      pList: [],
      formInline: {
        deptCode: null,
        provinceList: [
          {
            cityList: [],
            provinceCode: "",
            provinceName: "",
            cList: [],
          },
        ],
      },
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    // 获取高度
    this.tableHeight = document.body.clientHeight - 210 + "px";
  },
  methods: {
    onExport() {
      console.log(1212);
      this.axios.get("DEPTEXPORT", {}).then((result) => {
        if (result instanceof Blob) {
          const blob = new Blob([result]);
          console.log(blob);
          const fileName = "大区关联表" + ".xlsx";
          if ("download" in document.createElement("a")) {
            // 非IE下载
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName);
          }
        } else {
          this.$message.error("导出失败！");
        }
      });
    },
    handleChange(file, fileList) {
      let formData = new FormData();
      formData.append("file", file.raw);
      console.log(formData);
      this.upload_file(formData);
    },
    upload_file(val) {
      let params = val;
      this.$store.dispatch("app/setLoading", true);
      console.log(params);
      this.axios
        .post("DEPTIMPORT", val)
        .then((result) => {
          console.log("查询返回", result);
          if (result.code === 0) {
            this.$message({
              message: "导入成功",
              type: "success",
            });
            this.getList();
          } else {
            const str = result.msg.replace(/(\n|\r|\r\n|↵)/g, "<br/>");
            this.$message({
              dangerouslyUseHTMLString: true,
              type: "error",
              message: str,
            });
          }
          this.$store.dispatch("app/setLoading", false);
        })
        .catch(() => {
          this.isLoading = false;
          this.$store.dispatch("app/setLoading", false);
        });
    },
    async onChangeDept(val) {
      console.log("val", val);
      const validRes = await this.validDept();
      if (validRes) {
        this.$message.warning("该大区已存在，请重新选择");
        this.formInline.deptCode = "";
        return;
      }
      this.getProvince();
      this.formInline.provinceList.forEach((item) => {
        item.cityList = [];
      });
    },
    async handleEdit(row) {
      const deptDetail = await this.axios
        .get("DEPTDETAIL", { deptCode: row.deptCode })
        .then((res) => {
          if (res.code === 0) {
            return res.data;
          } else {
            this.$message.error(res.msg);
          }
        });
      this.formInline.deptCode = deptDetail.deptCode;

      this.formInline.provinceList = deptDetail.provinceList.map((item) => {
        return {
          cList: item.cityList,
          cityList: item.cityList
            .filter((oItem) => oItem.selected)
            .map((sItem) => sItem.cityCode),
          provinceCode: item.provinceCode,
          provinceName: item.provinceName,
        };
      });
      this.showModel = true;
      this.modelTitle = "修改大区";
      this.getProvince();
      this.getDept();
    },
    async validDept() {
      const result = await this.axios
        .get("DEPTEXIST", { deptCode: this.formInline.deptCode })
        .then((res) => {
          return res.data;
        });
      return result;
    },
    // 创建编辑提交
    submitForm() {
      this.modelTitle === "修改大区" ? this.editAction() : this.addAction();
    },
    editAction() {
      this.axios.post("DEPTUPDATE", this.formInline).then((result) => {
        if (result.code == 0) {
          this.onCancelForm();
          this.getList();
        } else {
          this.$message.error(result.msg);
        }
      });
    },
    async addAction() {
      const validRes = await this.validDept();
      if (validRes) {
        this.$message.warning("该大区已存在，请重新选择");
        return;
      }
      this.axios.post("DEPTADD", this.formInline).then((result) => {
        if (result.code == 0) {
          this.onCancelForm();
          this.getList();
        } else {
          this.$message.error(result.msg);
        }
      });
    },
    onCancelForm() {
      this.showModel = false;
      this.formInline = {
        deptCode: null,
        provinceList: [
          {
            cityList: [],
            provinceCode: "",
            provinceName: "",
            cList: [],
          },
        ],
      };
    },
    onFocusProvince() {
      if (!this.formInline.deptCode) {
        this.$message.warning("请先选择大区");
        return;
      }
    },
    onSetProvinceDisable() {
      this.pList.forEach((pItem) => {
        if (
          this.formInline.provinceList.some(
            (item) => pItem.provinceCode === item.provinceCode
          )
        ) {
          pItem.disabled = true;
        } else {
          pItem.disabled = false;
        }
      });
    },
    async getCityByProvince(provinceCode) {
      const cList = await this.axios
        .get("GETCITYLIST", {
          deptCode: this.formInline.deptCode,
          provinceCode,
        })
        .then((result) => {
          if (result.code === 0) {
            return result.data;
          } else {
            return [];
          }
        });
      return cList;
    },
    async onchangeProvince(val, index) {
      this.formInline.provinceList[index]["cityList"] = [];
      this.pList.forEach((item) => {
        if (item.provinceCode === val) {
          this.formInline.provinceList[index]["provinceName"] =
            item.provinceName;
        }
      });
      this.onSetProvinceDisable();
      this.formInline.provinceList[index]["cList"] =
        await this.getCityByProvince(val);
    },
    onRemove(index) {
      this.formInline.provinceList.splice(index, 1);
      this.onSetProvinceDisable();
    },
    onMore() {
      this.formInline.provinceList.push({
        cityList: [],
        provinceCode: "",
        provinceName: "",
        cList: [],
      });
      this.onSetProvinceDisable();
    },
    getProvince() {
      this.axios.get("GETPROVINCELIST", {}).then((result) => {
        if (result.code === 0) {
          this.pList = result.data;
          this.onSetProvinceDisable();
        }
      });
    },
    getDept() {
      this.axios.get("GETDEPTLIST", {}).then((result) => {
        if (result.code === 0) {
          this.deptList = result.data;
        }
      });
    },
    onAdd() {
      this.showModel = true;
      this.modelTitle = "添加大区";
      this.formInline = {
        deptCode: null,
        provinceList: [
          {
            cityList: [],
            provinceCode: "",
            provinceName: "",
            cList: [],
          },
        ],
      };
      this.pList = [];
      this.getDept();
    },

    // 删除
    handleDelete(row) {
      this.$confirm("您确定要删除？", "提示", {
        confirmButtonText: "确定",
        canceButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.axios
          .postQuery("DEPTDELETE", {
            deptCode: row.deptCode,
          })
          .then((result) => {
            if (result.code == 0) {
              this.$message({
                type: "success",
                message: "删除成功",
              });
              this.getList();
            } else {
              this.$message.error(result.msg);
            }
          });
      });
    },

    // 获取用户列表
    getList() {
      this.axios.get("DEPTLIST", "").then((result) => {
        if (result.code === 0) {
          this.tableData = result.data;
        }
      });
    },
    empty() {
      this.$confirm("您确定要清空所有大区么？", "提示", {
        confirmButtonText: "确定",
        canceButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.axios.post("DEPTCLEAR", "").then((result) => {
          if (result.code == 0) {
            this.$message({
              type: "success",
              message: "全部清空成功",
            });
            this.getList();
          } else {
            this.$message.error(result.msg);
          }
        });
      });
    },
  },
};
</script>

<style>
.modular-box {
  display: flex;
  flex-direction: column;
}
.el-cascader__tags .el-tag {
  color: #909399;
}
.new-card .el-card__header {
  padding: 13px 20px;
}
.new-card .el-card__body {
  padding: 20px;
}
.el-dialog__headerbtn .el-dialog__close {
  color: #1cd097;
}
.el-table::before {
  z-index: inherit;
}
.new-tag1 {
  border-radius: 4px;
  background: #2672ff10;
  color: #2672ff;
  border: 1px solid #2672ff50;
  padding: 4px 10px;
}
.new-tag2 {
  border-radius: 4px;
  background: #ffa50f10;
  color: #ffa50f;
  border: 1px solid #ffa50f50;
  padding: 4px 10px;
}
.new-tag3 {
  border-radius: 4px;
  background: #ff532210;
  color: #ff5322;
  border: 1px solid #ff532250;
  padding: 4px 10px;
}
.new-tag4 {
  border-radius: 4px;
  background: #9d4de610;
  color: #9d4de6;
  border: 1px solid #9d4de650;
  padding: 4px 10px;
}
.new-tag5 {
  border-radius: 4px;
  background: #1cd09710;
  color: #1cd097;
  border: 1px solid #1cd09750;
  padding: 4px 10px;
  width: 90px;
  margin: 0 auto;
}
</style>

<style lang="scss" scoped>
.content {
  overflow-y: auto;
  height: 90vh;
  .card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .card-header-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      .top-title {
        font-size: 16px;
        color: #262626;
        font-weight: bold;
      }
      .question {
        width: 16px;
        height: 16px;
        margin-left: 4px;
      }
      .question:hover {
        cursor: pointer;
      }
    }
    .card-header-right {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }
  }
  .auth-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .auth-show-input {
    width: 300px;
  }
}
</style>
